<template>
  <section>
    <div class="d-flex justify-end my-4">
      <v-btn dark color="secondary" @click="leyesDialog = true">Agregar</v-btn>
    </div>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="items"
      @paginar="paginate"
      :total_registros="totalRows"
    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="editarLey(item)"
              v-bind="attrs"
              v-on="on"
              large
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </template>

          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="(deleteDialog = true), (leySeleccionada = item.id)"
              v-bind="attrs"
              v-on="on"
              large
              color="primary"
            >
              mdi-delete
            </v-icon>
          </template>

          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <v-dialog persistent max-width="1000" class="modal" v-model="leyesDialog">
      <v-card max-width="1000" max-height="1050">
        <v-btn @click="clearInfo" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">
                {{ editable ? "Editar" : "Agregar" }} Ley
              </p>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="Nombre *"
                  outlined
                  v-model="leyObj.nombre"
                  @input="$v.leyObj.nombre.$touch()"
                  :error-messages="nombreError"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="Años *"
                  @input="$v.leyObj.anios.$touch()"
                  :error-messages="aniosError"
                  outlined
                  v-model="leyObj.anios"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Descripción *"
                  outlined
                  @input="$v.leyObj.descripcion.$touch()"
                  :error-messages="descError"
                  v-model="leyObj.descripcion"
                />
              </v-col>
            </v-row>
          </section>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            class="text-capitalize mx-8 mb-2 mb-sm-0"
            outlined
            @click="clearInfo()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            @click="guardarLey"
            class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogoConfirmacion
      :show="deleteDialog"
      title="¿Deseas remover esta ley?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="(deleteDialog = false), (leySeleccionada = null)"
      @confirm="eliminarLey()"
    />
  </section>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import { helpers, required, numeric } from "vuelidate/lib/validators";
import dialogoConfirmacion from "../../../components/ConfirmationDialogComponent.vue";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

export default {
  name: "LeyesComponent",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Descripción",
        value: "descripcion",
      },
      {
        text: "Años",
        value: "anios",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    leyesDialog: false,
    tabMenu: null,
    leyObj: {
      id: null,
      nombre: null,
      descripcion: null,
      anios: null,
    },
    deleteDialog: false,
    leySeleccionada: null,
    editable: false,
  }),
  validations: {
    leyObj: {
      nombre: { required, txtField },
      descripcion: { required, txtField },
      anios: { required, numeric },
    },
  },
  computed: {
    nombreError() {
      const errors = [];
      if (!this.$v.leyObj.nombre.$dirty) return errors;
      !this.$v.leyObj.nombre.required &&
        errors.push("El campo nombre  es requerido");
      !this.$v.leyObj.nombre.txtField &&
        errors.push("El campo nombre solo admite caracteres alfanuméricos");
      return errors;
    },
    descError() {
      const errors = [];
      if (!this.$v.leyObj.descripcion.$dirty) return errors;
      !this.$v.leyObj.descripcion.required &&
        errors.push("El campo descripción  es requerido");
      !this.$v.leyObj.descripcion.txtField &&
        errors.push(
          "El campo descripción solo admite caracteres alfanuméricos"
        );
      return errors;
    },
    aniosError() {
      const errors = [];
      if (!this.$v.leyObj.anios.$dirty) return errors;
      !this.$v.leyObj.anios.required &&
        errors.push("El campo años  es requerido");
      !this.$v.leyObj.anios.numeric &&
        errors.push("El campo descripción solo admite números");
      return errors;
    },
  },
  methods: {
    async getLeyes() {
      const { status, data, headers } =
        await this.services.Sanciones.getLeyesList({
          page: this.page,
          per_page: this.perPage,
        });

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getLeyes();
    },
    clearInfo() {
      this.leyObj.id = null;
      this.leyObj.nombre = null;
      this.leyObj.descripcion = null;
      this.leyObj.anios = null;
      this.leyesDialog = false;
      this.editable = false;
      this.$v.$reset();
    },
    async guardarLey() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let response = null;

        if (this.editable) {
          response = await this.services.Sanciones.putLeyes(
            this.leyObj.id,
            this.leyObj
          );
        } else {
          response = await this.services.Sanciones.postLeyes(this.leyObj);
        }

        if (response.status == 201 || response.status == 200) {
          this.temporalAlert({
            show: true,
            message: `Ley ${this.editable ? "editada" : "guardada"} con éxito`,
            type: "success",
          });
          this.clearInfo();
          this.getLeyes();
        }
      }
    },
    editarLey(item) {
      this.leyObj.id = item.id;
      this.leyObj.nombre = item.nombre;
      this.leyObj.descripcion = item.descripcion;
      this.leyObj.anios = item.anios;

      this.editable = true;
      this.leyesDialog = true;
    },

    async eliminarLey() {
      const { status } = await this.services.Sanciones.deleteLeyes(
        this.leySeleccionada
      );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Ley eliminada con éxito`,
          type: "success",
        });
        this.leySeleccionada = null;
        this.deleteDialog = false;
        this.getLeyes();
      }
    },
  },
  created() {
    this.getLeyes();
  },
};
</script>
