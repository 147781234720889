<template>
  <section>
    <p class="text-h6 secondary--text">Administración de sanciones</p>

    <v-tabs v-model="tabMenu" background-color="bgMinsal" show-arrows>
      <v-tabs-slider color="secondary"></v-tabs-slider>
      <v-tab href="#tab-1" class="secondary--text v-step-0"
        ><v-icon>mdi-clipboard-check </v-icon> Leyes</v-tab
      >
      <v-tab href="#tab-2" class="secondary--text v-step-1"
        ><v-icon>mdi-clipboard-text-clock-outline</v-icon> Sanciones</v-tab
      >
    </v-tabs>

    <v-tabs-items style="width: 100%" v-model="tabMenu" class="v-step-2">
      <v-tab-item value="tab-1">
        <LeyesComponent />
      </v-tab-item>
      <v-tab-item value="tab-2" class="tabStyle">
        <SancionesComponent />
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>
<script>
import LeyesComponent from "./components/LeyesComponent.vue";
import SancionesComponent from "./components/SancionesComponent.vue";
export default {
  name: "AdministracionSancionesView",
  components: {
    LeyesComponent,
    SancionesComponent
  },
  data: () => ({
    tabMenu: null,
  }),
};
</script>
