<template>
  <section>
    <div class="d-flex justify-end my-4">
      <v-btn dark color="secondary" @click="sancionesDialog = true"
        >Agregar</v-btn
      >
    </div>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="items"
      @paginar="paginate"
      :total_registros="totalRows"
    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="editarSancion(item)"
              v-bind="attrs"
              v-on="on"
              large
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </template>

          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="(deleteDialog = true), (sancionSeleccionada = item.id)"
              v-bind="attrs"
              v-on="on"
              large
              color="primary"
            >
              mdi-delete
            </v-icon>
          </template>

          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <v-dialog
      persistent
      max-width="1000"
      class="modal"
      v-model="sancionesDialog"
    >
      <v-card max-width="1000" max-height="1050">
        <v-btn @click="clearInfo" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">
                {{ editable ? "Editar" : "Agregar" }} sanción
              </p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Ley sanción *"
                  outlined
                  :items="leyes_list"
                  item-value="id"
                  item-text="nombre"
                  @blur="$v.sancion.id_ley_sancion.$touch()"
                  :error-messages="leyError"
                  v-model="sancion.id_ley_sancion"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="Nombre *"
                  outlined
                  v-model="sancion.nombre"
                  @input="$v.sancion.nombre.$touch()"
                  :error-messages="nombreError"
                />
              </v-col>
            </v-row>
          </section>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            class="text-capitalize mx-8 mb-2 mb-sm-0"
            outlined
            @click="clearInfo()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            @click="guardarSancion"
            class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogoConfirmacion
      :show="deleteDialog"
      title="¿Deseas remover esta sanción?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="(deleteDialog = false), (sancionSeleccionada = null)"
      @confirm="eliminarSancion()"
    />
  </section>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import dialogoConfirmacion from "../../../components/ConfirmationDialogComponent.vue";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

export default {
  name: "SancionesComponent",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Ley sanción",
        value: "ley_sancion.nombre",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    sancionesDialog: false,
    sancion: {
      id: null,
      nombre: null,
      id_ley_sancion: null,
    },
    deleteDialog: false,
    sancionSeleccionada: null,
    editable: false,
    leyes_list: [],
  }),
  validations: {
    sancion: {
      nombre: { required, txtField },
      id_ley_sancion: { required },
    },
  },
  computed: {
    nombreError() {
      const errors = [];
      if (!this.$v.sancion.nombre.$dirty) return errors;
      !this.$v.sancion.nombre.required &&
        errors.push("El campo nombre  es requerido");
      !this.$v.sancion.nombre.txtField &&
        errors.push("El campo nombre solo admite caracteres alfanuméricos");
      return errors;
    },
    leyError() {
      const errors = [];
      if (!this.$v.sancion.id_ley_sancion.$dirty) return errors;
      !this.$v.sancion.id_ley_sancion.required &&
        errors.push("El campo ley sanción  es requerido");
      return errors;
    },
  },
  methods: {
    async getSanciones() {
      const { status, data, headers } =
        await this.services.Sanciones.getDescripcionSanciones({
          page: this.page,
          per_page: this.perPage,
        });

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    async getLeyes() {
      const { status, data } = await this.services.Sanciones.getLeyesList({
        pagination: false,
      });

      if (status == 200) {
        this.leyes_list = data;
      }
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getSanciones();
    },
    clearInfo() {
      this.sancion.id = null;
      this.sancion.nombre = null;
      this.sancion.id_ley_sancion = null;
      this.sancionesDialog = false;
      this.editable = false;
      this.$v.$reset();
    },
    async guardarSancion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let response = null;

        if (this.editable) {
          response = await this.services.Sanciones.putDescripcionSanciones(
            this.sancion.id,
            this.sancion
          );
        } else {
          response = await this.services.Sanciones.postDescripcionSanciones(
            this.sancion
          );
        }

        if (response.status == 201 || response.status == 200) {
          this.temporalAlert({
            show: true,
            message: `Sanción ${
              this.editable ? "editada" : "guardada"
            } con éxito`,
            type: "success",
          });
          this.clearInfo();
          this.getSanciones();
        }
      }
    },
    editarSancion(item) {
      this.sancion.id = item.id;
      this.sancion.nombre = item.nombre;
      this.sancion.id_ley_sancion = item.id_ley_sancion;

      this.editable = true;
      this.sancionesDialog = true;
    },

    async eliminarSancion() {
      const { status } =
        await this.services.Sanciones.deleteDescripcionSanciones(
          this.sancionSeleccionada
        );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Sanción eliminada con éxito`,
          type: "success",
        });
        this.sancionSeleccionada = null;
        this.deleteDialog = false;
        this.getSanciones();
      }
    },
  },
  created() {
    this.getSanciones();
    this.getLeyes();
  },
};
</script>
